<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <br><br>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Analytic Entity Type</label>
        <multiselect
          v-model="data.analyticEntityType.selected"
          :options="data.analyticEntityType.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('AnalyticEntityType')">{{
          errors.first("AnalyticEntityType")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Erp Mapping" v-model="data.ErpMapping"/>
        <span class="text-danger text-sm" v-show="errors.has('ErpMapping')">{{ errors.first('ErpMapping') }}</span>
      </div>
    </div>
    <!-- <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Create/Update Date</label>
          <datepicker :inline="false" v-model="data.createDate"></datepicker>
        </div>
    </div> -->
<!-- 
    <span>
      <b>Journal Structure</b>
    </span>
    <br><br>
    <div class="vx-col sm:w-12/12 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-4/12">
            <label class="vs-input--label">ERP Analytic</label>
          </div>
          <div class="vx-col w-3/12">
            <label class="vs-input--label">ERP Analytic Description</label>
          </div>
          <div class="vx-col w-4/12">
            <label class="vs-input--label">Entity Unit</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label"></label>
          </div>
        </div>
    </div>
    
    <div class="vx-col sm:w-12/12 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <div class="vx-row mb-2">
          <div class="vx-col w-4/12">
            <multiselect
            v-model="items[indextr].analytic.selected"
            :options="items[indextr].analytic.options"
            track-by="ID"                     
            :max-height="125"
            :show-labels="false"
            label="Name"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('Analytic')"
                >{{ errors.first("Analytic") }}</span
            >
          </div>

          <div class="vx-col w-3/12">
            <textarea class="w-full" v-model="items[indextr].analyticDescription"></textarea>
          </div>
          
            <div class="vx-col w-4/12">
              <multiselect
              v-model="items[indextr].entityUnit.selected"
              :options="items[indextr].entityUnit.options"
              placeholder="Type to search"
              :max-height="125"
              v-validate="'required'"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option.name }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
          
          <div class="vx-col w-1/12" style="display: flex">
              
              <div class="vx-col w-full" style="text-align: center; margin: auto; margin-bottom: 0">
                  <vs-button
                      v-if="items[indextr].status=='minus'"
                      style="display: table-cell;"
                      v-on:click="handleRemoveItem(indextr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                  ></vs-button>
                  <vs-button
                      v-else
                      style="display: table-cell;"
                      v-on:click="handleAddItem()"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                  ></vs-button>
              </div>
          </div>

      </div>
    </div> -->


    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          //createDate: "", 
          ErpMapping: "", 
          analyticEntityType: {
            options: ["Distribution Channel", "Sales Channel", "Product Category", "Territory", "Principal"],
            selected: null
          },
          // analyticID: [],
          // entityUnitID: [],
          // analyticDescription: [],
        },
        // items: [{
        //     num: 0,
        //     status: "plus",
        //     analyticDescription: "",
        //     analytic:{
        //       selected: [],
        //       options: [],
        //     },
        //     entityUnit:{
        //         selected: [],
        //         options: [],
        //     },
        // }],
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        
        if (!this.data.analyticEntityType.selected) {
          this.errors.add({
            field: "AnalyticEntityType",
            msg: "The Analytic Entity Type field is required",
          });
          result = false;
        }

        // var _this = this
        // this.items.forEach(function(element, index){
        //     _this.data.analyticID[index] = _this.items[index].analytic.selected.ID
        //     _this.data.entityUnitID[index] = _this.items[index].entityUnit.selected.ID
        //     _this.data.analyticDescription[index] = _this.items[index].analyticDescription
        // })

        if (result) {
          
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        //analytic_date: this.data.createDate,
        analytic_entity_type: this.data.analyticEntityType.selected,
        erp_mapping: this.data.ErpMapping,

        // analytic_id: this.data.analyticID,
        // entity_unit_id: this.data.entityUnitID,
        // description: this.data.analyticDescription,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.data.ID = resp.data.ID;
            //this.data.createDate = resp.data.mapping.AnalyticDate;
            this.data.ErpMapping = resp.data.ErpMapping;
            this.data.analyticEntityType.selected = resp.data.AnalyticEntityType;

            // this.items = [];
            // var statusAdd;
            // for(var k = 0; k < resp.data.structure.length; k++){
            //   if(k == 0){
            //       statusAdd = "plus"
            //   }else{
            //       statusAdd = "minus"
            //   }
            //   _this.items.push({
            //       status: statusAdd,
            //       analyticDescription: resp.data.structure[k].Description,
            //       analytic:{
            //         selected: [],
            //         options: [],
            //       },
            //       entityUnit:{
            //           selected: [],
            //           options: [],
            //       },
            //   }) 
            //   if (resp.data.structure[k].PostingRuleAccountTypeId) {
            //       this.setAccountTypeSelected(k, resp.data.account[k].PostingRuleAccountTypeId);
            //   }
            // }


          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataAnalytic() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/analytic", {
          params: {
            order: "name",
            sort: "asc",
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            var _this = this
            this.items.forEach(function(element, index){
              _this.items[index].analytic.options = resp.data.records;
            })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataEntityUnit(val) {
      this.$vs.loading();
      if(val == "Distribution Channel") {
        this.$http
          .get("/api/v1/master/customer-category", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              var _this = this
              this.items.forEach(function(element, index){
                _this.items[index].entityUnit.options = resp.data.records;
              })
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
        });
      } else if(val == "Sales Channel") {
        this.$http
          .get("/api/v1/master/sales-channel", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              var _this = this
              this.items.forEach(function(element, index){
                _this.items[index].entityUnit.options = resp.data.records;
              })
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
        });
      } else if(val == "Product Category") {
        this.$http
          .get("/api/v1/master/product-category", {
            params: {
              order: "name",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.status == "success") {
              var _this = this
              this.items.forEach(function(element, index){
                _this.items[index].entityUnit.options = resp.data.records;
              })
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
        });
      }
    },
    setAnalyticSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/analytic/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.analytic.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setDefaultDate() {
      var day = new Date();
      this.data.createDate = day
    },
    handleAddItem(){
      var _this = this
      this.items.splice(this.items.length+1, 0, {
          num: 0,
          status: "minus",
          analyticDescription: "",
          analytic:{
            selected: [],
            options: _this.items[0].analytic.options,
          },
          entityUnit:{
              selected: [],
              options: _this.items[0].entityUnit.options,
          },
      })
      
    },
    handleRemoveItem(indextr){
      this.items.splice(indextr, 1)
    },
  },
  mounted() {
    //this.getDataAnalytic();
    //this.setDefaultDate();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      //this.getDataAnalytic();
      //this.setDefaultDate();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    // "data.analyticEntityType.selected": function (val) {
    //   var _this = this
    //   if (val) {
    //     this.errors.clear();
    //     this.getDataEntityUnit(val);
    //   } else {
    //     this.items.forEach(function(element, index){
    //       _this.items[index].entityUnit.options = [];
    //       _this.items[index].entityUnit.selected = null;
    //     })
    //   }
    // },
  },
};
</script>
<style >
</style>

<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <br><br>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Document</label>
        <multiselect
          v-model="data.document.selected"
          :options="data.document.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Document')">{{
          errors.first("Document")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Triggered From</label>
        <multiselect
          v-model="data.postingRuleTrigger.selected"
          :options="data.postingRuleTrigger.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Trigger')">{{
          errors.first("Trigger")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Description</label>
        <textarea class="w-full" v-model="data.description"></textarea>
      </div>
    </div>

    <span>
      <b>Journal Structure</b>
    </span>
    <br><br>
    <div class="vx-col sm:w-12/12 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Account Type</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">COA</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label">Default</label>
          </div>
          <div class="vx-col w-2/12" style="display: flex">          
            <div class="vx-col w-1/4">
              <label class="vs-input--label">OU</label>
            </div>
            <div class="vx-col w-1/4">
              <label class="vs-input--label">AC</label>
            </div>
            <div class="vx-col w-1/3">
              <label class="vs-input--label">CC</label>
            </div>
            <div class="vx-col w-1/4">
              <label class="vs-input--label">Line</label>
            </div>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">CC Code</label>
          </div>
          <div class="vx-col w-2/12">
            <label class="vs-input--label">Value Calculation</label>
          </div>
          <div class="vx-col w-1/12">
            <label class="vs-input--label"></label>
          </div>
        </div>
    </div>
    
    <div class="vx-col sm:w-12/12 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <div class="vx-row mb-2">
          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].postingRuleAccountType.selected"
            :options="items[indextr].postingRuleAccountType.options"
            track-by="ID"                     
            :max-height="125"
            :show-labels="false"
            label="Name"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('AccountType')"
                >{{ errors.first("AccountType") }}</span
            >
          </div>

          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].coa.selected"
            :options="items[indextr].coa.options"
            track-by="ID"                     
            :max-height="125"
            :show-labels="false"
            label="Name"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option.coa.Code }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option.coa.Code }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('Coa')"
                >{{ errors.first("Coa") }}</span
            >
          </div>
          
            <div class="vx-col w-1/12">
              <multiselect
              v-model="items[indextr].defaultJournal.selected"
              :options="items[indextr].defaultJournal.options"
              placeholder="Type to search"
              :max-height="125"
              v-validate="'required'"
            >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title"
                    >{{ props.option }}</span
                  >
                </span>
              </template>

              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"
                    >{{ props.option }}</span
                  >
                </div>
              </template>
            </multiselect>
          </div>
          <div class="vx-col w-2/12" style="display: flex">          
            <div class="vx-col w-1/4">
              <vs-checkbox color="success" v-model="items[indextr].ou" vs-value="1"></vs-checkbox>
            </div>
            <div class="vx-col w-1/4">
              <vs-checkbox color="success" v-model="items[indextr].ac" vs-value="1"></vs-checkbox>
            </div>
            <div class="vx-col w-1/4"> 
              <vs-checkbox color="success" v-model="items[indextr].cc" vs-value="1"></vs-checkbox>
            </div>
            <div class="vx-col w-1/4"> 
              <vs-checkbox color="success" v-model="items[indextr].line" vs-value="1"></vs-checkbox>
            </div>
          </div>
          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].costCenter.selected"
            :options="items[indextr].costCenter.options"
            track-by="ID"                     
            :max-height="125"
            :show-labels="false"
            label="Name"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }} - {{ props.option.Code }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }} - {{ props.option.Code }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('CostCenter')"
                >{{ errors.first("CostCenter") }}</span
            >
          </div>

          
          <div class="vx-col w-2/12">
            <multiselect
            v-model="items[indextr].postingRuleValueCalculation.selected"
            :options="items[indextr].postingRuleValueCalculation.options"
            track-by="ID"                     
            :max-height="125"
            :show-labels="false"
            label="Name"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option.Name }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('ValueCalculation')"
                >{{ errors.first("ValueCalculation") }}</span
            >
          </div>
          
          <div class="vx-col w-1/12" style="display: flex">
              
              <div class="vx-col w-full" style="text-align: center; margin: auto; margin-bottom: 0">
                  <vs-button
                      v-if="items[indextr].status=='minus'"
                      style="display: table-cell;"
                      v-on:click="handleRemoveItem(indextr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                  ></vs-button>
                  <vs-button
                      v-else
                      style="display: table-cell;"
                      v-on:click="handleAddItem()"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                  ></vs-button>
              </div>
          </div>

      </div>
    </div>


    <br><br>
    <span>
      <b>Journal Note</b>
    </span>
    <br><br>
    <div class="vx-col sm:w-2/2 w-full mb-2">
        <div class="vx-row mb-2">
            <div class="vx-col w-1/2" style="display: flex">
              <label class="vs-input--label">Document</label>
            </div>
        </div>
    </div>

    <div class="vx-col sm:w-2/2 w-full mb-2" v-for="(tr, indextr) in notes" :key="'a'+indextr">

      <div class="vx-row mb-2">
        <div class="vx-col w-1/2" style="display: flex">
          <div class="vx-col w-2/3 mr-4">
            <multiselect
            v-model="notes[indextr].information.selected"
            :options="notes[indextr].information.options"              
            :max-height="125"
            :show-labels="false"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </div>
            </template>
            </multiselect>
          </div>
          <div class="vx-col w-1/3">
            <vs-button
                v-if="notes[indextr].status=='minus'"
                style="display: table-cell;"
                v-on:click="handleRemoveNote(indextr)"
                color="danger"
                type="filled"
                icon-pack="feather"
                icon="icon-minus"
            ></vs-button>
            <vs-button
                v-else
                style="display: table-cell;"
                v-on:click="handleAddNote()"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
            ></vs-button>
          </div>
        </div>
          

      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          description: "", 
          postingRuleTrigger: {
            options: [],
            selected: null
          },
          document: {
            options: [],
            selected: null
          },
          
          ou: [],
          ac: [],
          cc: [],
          line: [],
          ccCode: [],
          postingRuleAccountTypeID: [],
          postingRuleValueCalculationID: [],
          coaID: [],
          CostCenterID: [],
          defaultJournalName: [],
          informationName: [],
        },
        items: [{
            num: 0,
            status: "plus",
            ou: null,
            ac: null,
            cc: null,
            line: null,
            postingRuleAccountType:{
              selected: [],
              options: [],
            },
            coa:{
                selected: [],
                options: [],
            },
            costCenter:{
                selected: [],
                options: [],
            },
            postingRuleValueCalculation:{
                selected: [],
                options: [],
            },
            defaultJournal: {
              options: ["Debit", "Kredit"],
              selected: "Debit"
            },
        }],
        notes: [{
          status: "plus",
          information: {
              options: ["CN Number", "DN Number", "Distribution Channel", "GI Number", "GR Number", "Inovice Number", "PO Number", "Payment Number", "SO Number", "SR Number", "Supplier"],
              selected: null
            },
        }],
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        
        if (!this.data.document.selected) {
          this.errors.add({
            field: "Document",
            msg: "The Document field is required",
          });
          result = false;
        }

        if (!this.data.postingRuleTrigger.selected) {
          this.errors.add({
            field: "Trigger",
            msg: "The Trigger field is required",
          });
          result = false;
        }

        var _this = this
        this.items.forEach(function(element, index){
            _this.data.postingRuleAccountTypeID[index] = _this.items[index].postingRuleAccountType.selected.ID
            _this.data.coaID[index] = _this.items[index].coa.selected.coa.ID
            _this.data.defaultJournalName[index] = _this.items[index].defaultJournal.selected
            _this.data.ou[index] = _this.items[index].ou
            _this.data.ac[index] = _this.items[index].ac
            _this.data.cc[index] = _this.items[index].cc
            _this.data.line[index] = _this.items[index].line
            _this.data.CostCenterID[index] = _this.items[index].costCenter.selected.ID
            _this.data.postingRuleValueCalculationID[index] = _this.items[index].postingRuleValueCalculation.selected.ID
        })

        this.notes.forEach(function(element, index){
            _this.data.informationName[index] = _this.notes[index].information.selected
        })

        if (result) {
          
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        process_document_id: this.data.document.selected.ID,
        posting_rule_trigger_id: this.data.postingRuleTrigger.selected.ID,
        description: this.data.description,
        posting_rule_account_type_id: this.data.postingRuleAccountTypeID,
        coa_ref_id: this.data.coaID,
        default_journal: this.data.defaultJournalName,
        operating_unit_type: this.data.ou,
        analytic_code_type: this.data.ac,
        cost_center_type: this.data.cc,
        line_type: this.data.line,
        cost_center_id: this.data.CostCenterID,
        posting_rule_value_calculation_id: this.data.postingRuleValueCalculationID,
        type: this.data.informationName,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.data.ID = resp.data.posting.ID;
            this.data.description = resp.data.posting.Description;
            console.log(resp.data.posting.Description)
            if (resp.data.posting.ProcessDocumentID) {
              this.setDocumentSelected(resp.data.posting.ProcessDocumentID);
            }
            if (resp.data.posting.PostingRuleTriggerID) {
              this.setTriggerSelected(resp.data.posting.PostingRuleTriggerID);
            }

            this.items = [];
            var statusAdd;
            for(var k = 0; k < resp.data.account.length; k++){
              if(k == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }
              _this.items.push({
                  ou: resp.data.account[k].OperatingUnitType,
                  ac: resp.data.account[k].AnalyticCodeType,
                  cc: resp.data.account[k].CostCenterType,
                  line: resp.data.account[k].LineType,
                  status: statusAdd,
                  postingRuleAccountType:{
                    selected: [],
                    options: [],
                  },
                  coa:{
                      selected: [],
                      options: [],
                  },
                  costCenter:{
                      selected: [],
                      options: [],
                  },
                  postingRuleValueCalculation:{
                      selected: [],
                      options: [],
                  },
                  defaultJournal: {
                    options: ["Debit", "Kredit"],
                    selected: resp.data.account[k].DefaultJournal
                  },
                  // unitData[k].options: resp.data.sales_return_line[k].Quantity.toString(),
              }) 
              if (resp.data.account[k].PostingRuleAccountTypeId) {
                  this.setAccountTypeSelected(k, resp.data.account[k].PostingRuleAccountTypeId);
              }
              if (resp.data.account[k].CoaRefID) {
                  this.setCoaSelected(k, resp.data.account[k].CoaRefID);
              }
              if (resp.data.account[k].CostCenterID) {
                  this.setCostCenterSelected(k, resp.data.account[k].CostCenterID);
              }
              if (resp.data.account[k].PostingRuleValueCalculationID) {
                  this.setValueCalculationSelected(k, resp.data.account[k].PostingRuleValueCalculationID);
              }
            }

            this.notes = [];
            for(var j = 0; j < resp.data.note.length; j++){
              if(j == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }
              
              _this.notes.push({
                status: statusAdd,
                information: {
                  options: ["CN Number", "DN Number", "Distribution Channel", "GI Number", "GR Number", "Inovice Number", "PO Number", "Payment Number", "SO Number", "SR Number", "Supplier"],
                  selected: resp.data.note[j].Type
                },
              })
          
            }

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataDocument() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/process-document", {
          params: {
            order: "name",
            sort: "asc",
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.document.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTrigger() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/posting-rule-trigger", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.postingRuleTrigger.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataValueCalculation() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/posting-rule-value-calculation", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            var _this = this
            this.items.forEach(function(element, index){
              _this.items[index].postingRuleValueCalculation.options = resp.data.records;
            })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataAccountType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/posting-rule-account-type", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            var _this = this
            this.items.forEach(function(element, index){
              _this.items[index].postingRuleAccountType.options = resp.data.records;
            })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            var _this = this
            this.items.forEach(function(element, index){
              _this.items[index].coa.options = resp.data.records;
            })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            var _this = this
            this.items.forEach(function(element, index){
              _this.items[index].costCenter.options = resp.data.records;
            })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setDocumentSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/process-document/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.document.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setTriggerSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/posting-rule-trigger/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.postingRuleTrigger.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setAccountTypeSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/setting/posting-rule-account-type/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].postingRuleAccountType.selected = resp.data;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setCoaSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].coa.selected = resp.data;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setCostCenterSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/cost-center/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].costCenter.selected = resp.data;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setValueCalculationSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/setting/posting-rule-value-calculation/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].postingRuleValueCalculation.selected = resp.data;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    handleAddItem(){
      var _this = this
      this.items.splice(this.items.length+1, 0, {
          num: 0,
          status: "minus",
          ou: null,
          ac: null,
          cc: null,
          line: null,
          postingRuleAccountType:{
            selected: [],
            options: _this.items[0].postingRuleAccountType.options,
          },
          coa:{
              selected: [],
              options: _this.items[0].coa.options,
          },
          costCenter:{
              selected: [],
              options: _this.items[0].costCenter.options,
          },
          postingRuleValueCalculation:{
              selected: [],
              options: _this.items[0].postingRuleValueCalculation.options,
          },
          defaultJournal: {
            options: ["Debit", "Kredit"],
            selected: "Debit"
          },
      })
      
    },
    handleRemoveItem(indextr){
      this.items.splice(indextr, 1)
    },
    handleAddNote(){
      this.notes.splice(this.notes.length+1, 0, {
          status: "minus",
          information: {
            options: ["CN Number", "DN Number", "Distribution Channel", "GI Number", "GR Number", "Inovice Number", "PO Number", "Payment Number", "SO Number", "SR Number", "Supplier"],
            selected: null
          },
      })
      
    },
    handleRemoveNote(indextr){
      this.notes.splice(indextr, 1)
    },
  },
  mounted() {
    this.getDataDocument();
    this.getDataTrigger();
    this.getDataAccountType();
    this.getDataCoa();
    this.getDataCostCenter();
    this.getDataValueCalculation();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      this.getDataDocument();
      this.getDataTrigger();
      this.getDataAccountType();
      this.getDataCoa();
      this.getDataCostCenter();
      this.getDataValueCalculation();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>

<template>
  <div class="vx-col w-11/12 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <br><br>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Head Office</label>
        <vs-checkbox
          v-model="data.isHeadOffice"
          color="primary"
        ></vs-checkbox>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Code"
          v-model="data.code"
          name="Code"
          v-validate="'required'"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-model="data.name"
          name="Name"
          v-validate="'required'"
        />
      </div>
    </div>    
    <!-- <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="External Code"
          v-model="data.externalCode"
        />
      </div>
    </div> -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="ERP Mapping"
          v-model="data.erpMapping"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Default Cost Center</label>
        <multiselect
        v-model="costCenter.selected"
        :options="costCenter.options"
        track-by="ID"                     
        :max-height="125"
        :show-labels="false"
        label="Name"
        >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
            <span class="option__title"
                >{{ props.option.Name }} ({{ props.option.Code }})</span
            >
            </span>
        </template>

        <template slot="option" slot-scope="props">
            <div class="option__desc">
            <span class="option__title"
                >{{ props.option.Name }} ({{ props.option.Code }})</span
            >
            </div>
        </template>
        </multiselect>
        <span
            class="text-danger text-sm"
            v-show="errors.has('CostCenter')"
            >{{ errors.first("CostCenter") }}</span
        >
      </div>
    </div>
    <span>
      <b>Tax Data</b>
    </span>
    <br><br>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="NPWP"
          v-model="data.npwp"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="SPPKP"
          v-model="data.sppkp"
        />
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Tax Address"
          v-model="data.taxAddress"
        />
      </div>
    </div>

    <span>
      <b>Entity Data</b>
    </span>
    <br><br>
    <div class="vx-col sm:w-5/5 w-full mb-2">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/5">
            <label class="vs-input--label">Entity Type</label>
          </div>
          <div class="vx-col w-3/5">
            <label class="vs-input--label">Entity Unit</label>
          </div>
          <div class="vx-col w-1/5">
            
          </div>
        </div>
    </div>
    
    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <div class="vx-row mb-2">
          <div class="vx-col w-1/5">
            <multiselect
            v-model="items[indextr].entityType.selected"
            :options="items[indextr].entityType.options"        
            :max-height="125"
            :show-labels="false"
            label="Name"
            @select="entityTypeChange($event, indextr)"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('EntityType')"
                >{{ errors.first("EntityType") }}</span
            >
          </div>

          <div class="vx-col w-3/5">
            <multiselect
            v-model="items[indextr].entityUnit.selected"
            :options="items[indextr].entityUnit.options"
            track-by="code"                     
            :max-height="125"
            :show-labels="false"
            label="name"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option.name }} - {{ props.option.code }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option.name }} - {{ props.option.code }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('EntityUnit')"
                >{{ errors.first("EntityUnit") }}</span
            >
          </div>

          <div class="vx-col w-1/5" style="display: flex">
              
              <div class="vx-col w-full" style="text-align: center; margin: auto; margin-bottom: 0">
                  <vs-button
                      v-if="items[indextr].status=='minus'"
                      style="display: table-cell;"
                      v-on:click="handleRemoveItem(indextr)"
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                  ></vs-button>
                  <vs-button
                      v-else
                      style="display: table-cell;"
                      v-on:click="handleAddItem()"
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                  ></vs-button>
              </div>
            </div>

      </div>
    </div>


    <br><br>
    <span>
      <b>Bank Account Data</b>
    </span>
    <br><br>

    <div class="vx-col sm:w-12/12 w-full mb-2" v-for="(tr, indextr) in banks" :key="'a'+indextr">

      <div class="vx-col sm:w-12/12 w-full mb-2">
          <div class="vx-row mb-2">
              <div class="vx-col w-3/12" style="display: flex">
                <label class="vs-input--label">Bank Name</label>
              </div>
              <div class="vx-col w-2/12" style="display: flex">
                <label class="vs-input--label">Bank Branch</label>
              </div>
              <div class="vx-col w-2/12" style="display: flex">
                <label class="vs-input--label">Account Code</label>
              </div>
              <div class="vx-col w-2/12" style="display: flex">
                <label class="vs-input--label">Account Name</label>
              </div>
              <div class="vx-col w-3/12" style="display: flex">
                <label class="vs-input--label">Document</label>
              </div>
          </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-3/12" style="display: flex">
          <multiselect
          v-model="banks[indextr].bank.selected"
          :options="bank.options"              
          :max-height="125"
          track-by="ID"
          label="Name"
          >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
              <span class="option__title"
                  >{{ props.option.Name }}</span
              >
              </span>
          </template>

          <template slot="option" slot-scope="props">
              <div class="option__desc">
              <span class="option__title"
                  >{{ props.option.Name }}</span
              >
              </div>
          </template>
          </multiselect>
        </div>

        <div class="vx-col w-2/12" style="display: flex">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                v-model="banks[indextr].branch"
                v-validate="'required'"
              />
            </div>
        </div>
        <div class="vx-col w-2/12" style="display: flex">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                v-model="banks[indextr].accountCode"
                v-validate="'required'"
              />
            </div>
        </div>
        <div class="vx-col w-2/12" style="display: flex">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                v-model="banks[indextr].accountName"
                v-validate="'required'"
              />
            </div>
        </div>

        <div class="vx-col w-3/12" style="display: flex">
          <multiselect
          v-model="banks[indextr].processDocument.selected"
          :options="processDocument.options"              
          :max-height="125"
          track-by="Name"
          label="Name"
          >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
              <span class="option__title"
                  >{{ props.option.Name }}</span
              >
              </span>
          </template>

          <template slot="option" slot-scope="props">
              <div class="option__desc">
              <span class="option__title"
                  >{{ props.option.Name }}</span
              >
              </div>
          </template>
          </multiselect>
        </div>
      </div>

      <div class="vx-col sm:w-12/12 w-full mb-2">
        <div class="vx-row mb-2">
            <div class="vx-col w-3/12" style="display: flex">
              <label class="vs-input--label">COA Bank In</label>
            </div>
            <div class="vx-col w-3/12" style="display: flex">
              <label class="vs-input--label">COA Bank Out</label>
            </div>
            <div class="vx-col w-3/12" style="display: flex">
              <label class="vs-input--label">COA Bank Balance</label>
            </div>
            <div class="vx-col w-2/12" style="display: flex">
              <label class="vs-input--label">Account Type</label>
            </div>
            <div class="vx-col w-1/12" style="display: flex">
              <div class="vx-col w-1/2">Default</div>
              <div class="vx-col w-1/2"></div>
            </div>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-3/12" style="display: flex">
          <multiselect
          v-model="banks[indextr].coaIn.selected"
          :options="banks[indextr].coaIn.options"              
          :max-height="125"       
          track-by="Code"
          label="Code"
          >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
              <span class="option__title"
                  >{{ props.option.Code }}</span
              >
              </span>
          </template>

          <template slot="option" slot-scope="props">
              <div class="option__desc">
              <span class="option__title"
                  >{{ props.option.Code }}</span
              >
              </div>
          </template>
          </multiselect>
        </div>

        <div class="vx-col w-3/12" style="display: flex">
          <multiselect
          v-model="banks[indextr].coaOut.selected"
          :options="banks[indextr].coaOut.options"              
          :max-height="125"
          :show-labels="false"      
          track-by="Code"
          label="Code"
          >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
              <span class="option__title"
                  >{{ props.option.Code }}</span
              >
              </span>
          </template>

          <template slot="option" slot-scope="props">
              <div class="option__desc">
              <span class="option__title"
                  >{{ props.option.Code }}</span
              >
              </div>
          </template>
          </multiselect>
        </div>
        
        <div class="vx-col w-3/12" style="display: flex">
          <multiselect
          v-model="banks[indextr].coaBalance.selected"
          :options="banks[indextr].coaBalance.options"              
          :max-height="125"
          :show-labels="false"   
          track-by="Code"
          label="Code"
          >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
              <span class="option__title"
                  >{{ props.option.Code }}</span
              >
              </span>
          </template>

          <template slot="option" slot-scope="props">
              <div class="option__desc">
              <span class="option__title"
                  >{{ props.option.Code }}</span
              >
              </div>
          </template>
          </multiselect>
        </div>
        
        <div class="vx-col w-2/12" style="display: flex">
            <multiselect
            v-model="banks[indextr].accountType.selected"
            :options="banks[indextr].accountType.options"              
            :max-height="125"
            :show-labels="false"
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </div>
            </template>
            </multiselect>
        </div>

        <div class="vx-col w-1/12" style="display: flex">
          <div class="vx-col w-1/2">
          <vs-checkbox
          v-model="banks[indextr].isDefault"
          color="primary"
          v-on:change="checkedDefault(indextr)"
          ></vs-checkbox>
          <!-- <vs-radio v-model="banks[indextr].isDefault" name="IsDefault" vs-value="true"></vs-radio> -->
          </div>  
          <div class="vx-col w-1/2">
            <vs-button
                v-if="banks[indextr].status=='minus'"
                style="display: table-cell;"
                v-on:click="handleRemoveBank(indextr)"
                color="danger"
                type="filled"
                icon-pack="feather"
                icon="icon-minus"
            ></vs-button>
            <vs-button
                v-else
                style="display: table-cell;"
                v-on:click="handleAddBank()"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
            ></vs-button>
          </div>    
        </div>
      </div>
      <br>

    </div> <!-- end indextr -->

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          code: "",
          name: "",
          //externalCode: "", 
          isHeadOffice: false, 
          erpMapping: "", 
          externalMapping: "", 
          npwp: "",
          sppkp: "",
          taxAddress: "",
          
          branch: [],
          accountCode: [],
          accountName: [],
          entityUnitID: [],
          bankID: [],
          coaInID: [],
          coaOutID: [],
          coaBalanceID: [],
          entityTypeName: [],
          accountTypeName: [],
          isDefaultName: [],
          processDocumentID: [],
        },
        items: [{
            num: 0,
            status: "plus",
            entityUnit:{
                selected: [],
                options: [],
            },
            entityType: {
              options: ["Warehouse", "Territory"],
              selected: ""
            },
        }],
        bank:{
            options: [],
        },  
        processDocument:{
            options: [],
        },  
        entityUnit:{
            options: [],
        }, 
        costCenter:{
            selected: [],
            options: [],
        }, 
        banks: [{
          status: "plus",  
          branch: "", 
          accountCode: "",
          accountName: "", 
          isDefault: true,      
          bank:{
              selected: [],
              options: [],
          },       
          coaIn:{
              selected: [],
              options: [],
          },
          coaOut:{
              selected: [],
              options: [],
          },
          coaBalance:{
              selected: [],
              options: [],
          },
          processDocument:{
              selected: [],
              options: [],
          },
          accountType: {
            options: ["Saving Account", "Checking Account", "Digital Money"],
            selected: "Saving Account"
          },
        }],
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {

        var _this = this
        
        this.items.forEach(function(element, index){
            _this.data.entityTypeName[index] = _this.items[index].entityType.selected
            _this.data.entityUnitID[index] = _this.items[index].entityUnit.selected.ID ? _this.items[index].entityUnit.selected.ID : _this.items[index].entityUnit.selected.id     
        })
        
        this.banks.forEach(function(element, index){
            _this.data.bankID[index] = _this.banks[index].bank.selected.ID
            _this.data.coaInID[index] = _this.banks[index].coaIn.selected.ID
            _this.data.coaOutID[index] = _this.banks[index].coaOut.selected.ID
            _this.data.coaBalanceID[index] = _this.banks[index].coaBalance.selected.ID
            _this.data.accountTypeName[index] = _this.banks[index].accountType.selected
            _this.data.branch[index] = _this.banks[index].branch
            _this.data.accountCode[index] = _this.banks[index].accountCode
            _this.data.accountName[index] = _this.banks[index].accountName
            _this.data.isDefaultName[index] = _this.banks[index].isDefault
            _this.data.processDocumentID[index] = _this.banks[index].processDocument.selected.Code
        })
        


        if (result) {
          
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        code: this.data.code,
        name: this.data.name,
        //external_code: this.data.externalCode,
        is_head_office: this.data.isHeadOffice,
        erp_mapping: this.data.erpMapping,
        external_mapping: this.data.externalMapping,
        npwp: this.data.npwp,
        sppkp: this.data.sppkp,
        tax_address: this.data.taxAddress,
        entity_type: this.data.entityTypeName,
        entity_unit_id: this.data.entityUnitID,
        bank_id: this.data.bankID,
        chart_of_account_in_id: this.data.coaInID,
        chart_of_account_out_id: this.data.coaOutID,
        chart_of_account_balance_id: this.data.coaBalanceID,
        account_type: this.data.accountTypeName,
        branch: this.data.branch,
        account_code: this.data.accountCode,
        account_name: this.data.accountName,
        is_default: this.data.isDefaultName,
        ref_process_document_code: this.data.processDocumentID,
        cost_center_id: this.costCenter.selected.ID,

      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.data.ID = resp.data.ou.ID;
            this.data.code = resp.data.ou.Code;
            this.data.name = resp.data.ou.Name;
            //this.data.externalCode = resp.data.ou.ExternalCode;
            this.data.isHeadOffice = resp.data.ou.IsHeadOffice;
            this.data.erpMapping = resp.data.ou.ErpMapping;
            this.data.externalMapping = resp.data.ou.ExternalMapping;
            this.data.npwp = resp.data.ou.Npwp;
            this.data.sppkp = resp.data.ou.Sppkp;
            this.data.taxAddress = resp.data.ou.TaxAddress;

            if (resp.data.ou.CostCenterID) {
                  this.setCostCenterSelected(resp.data.ou.CostCenterID);
              }  
            

            this.items = [];
            var statusAdd;
            for(var k = 0; k < resp.data.warehouse.length; k++){
              
              this.entityTypeChange('Warehouse', k);
              if(k == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }

              _this.items.push({
                  status: statusAdd,
                  entityUnit:{
                      selected: [],
                      options: [],
                  },
                  entityType: {
                    options: ["Warehouse", "Territory"],
                    selected: "Warehouse"
                  },
              }) 
              if (resp.data.warehouse[k].WarehouseID) {
                  this.setWarehouseSelected(k, resp.data.warehouse[k].WarehouseID);
              }      
            }

            for(var i = 0; i < resp.data.territory.length; i++){
              this.entityTypeChange('Territory', k+i);
              if((k+i) == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }

              _this.items.push({
                  status: statusAdd,
                  entityUnit:{
                      selected: [],
                      options: [],
                  },
                  entityType: {
                    options: ["Warehouse", "Territory"],
                    selected: "Territory"
                  },
              }) 
              if (resp.data.territory[i].TerritoryID) {
                  this.setTerritorySelected((i+k), resp.data.territory[i].TerritoryID);
              }       
            }

            this.banks = [];
            if(resp.data.bank.length > 0){
              for(var j = 0; j < resp.data.bank.length; j++){
                if(j == 0){
                    statusAdd = "plus"
                }else{
                    statusAdd = "minus"
                }

                _this.banks.push({
                  status: statusAdd,
                  branch: resp.data.bank[j].BranchName, 
                  accountCode: resp.data.bank[j].AccountNumber,
                  accountName: resp.data.bank[j].AccountName, 
                  isDefault: resp.data.bank[j].IsDefault,          
                  bank:{
                      selected: [],
                      options: [],
                  },       
                  coaIn:{
                      selected: [],
                      options: [],
                  },
                  coaOut:{
                      selected: [],
                      options: [],
                  },
                  coaBalance:{
                      selected: [],
                      options: [],
                  },
                  processDocument:{
                      selected: [],
                      options: [],
                  },
                  accountType: {
                    options: ["Saving Account", "Checking Account", "Digital Money"],
                    selected: resp.data.bank[j].AccountType
                  },
                }) 
                
                if (resp.data.bank[j].BankID) {
                    this.setBankSelected(j, resp.data.bank[j].BankID);
                }

                if (resp.data.bank[j].ChartOfAccountInID) {
                    this.setCoaInSelected(j, resp.data.bank[j].ChartOfAccountInID);
                }

                if (resp.data.bank[j].ChartOfAccountOutID) {
                    this.setCoaOutSelected(j, resp.data.bank[j].ChartOfAccountOutID);
                }

                if (resp.data.bank[j].ChartOfAccountBalanceID) {
                    this.setCoaBalanceSelected(j, resp.data.bank[j].ChartOfAccountBalanceID);
                }

                if (resp.data.bank[j].RefProcessDocumentCode) {
                    this.setProcessDocumentSelected(j, resp.data.bank[j].RefProcessDocumentCode);
                }
            
              }
            }else{
                _this.banks.push({
                  status: "plus",  
                  branch: "", 
                  accountCode: "",
                  accountName: "", 
                  isDefault: true,          
                  bank:{
                      selected: [],
                      options: [],
                  },       
                  coaIn:{
                      selected: [],
                      options: [],
                  },
                  coaOut:{
                      selected: [],
                      options: [],
                  },
                  coaBalance:{
                      selected: [],
                      options: [],
                  },
                  processDocument:{
                      selected: [],
                      options: [],
                  },
                  accountType: {
                    options: ["Saving Account", "Checking Account", "Digital Money"],
                    selected: "Saving Account"
                  },
                }) 
            }

          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataWarehouse(index) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouses", {
          params: {
            order: "name",
            sort: "asc",
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.items[index].entityUnit.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataTerritory(index) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.items[index].entityUnit.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            var _this = this
            this.banks.forEach(function(element, index){
              resp.data.records.forEach(function(e, i){
                _this.banks[index].coaIn.options.push(e.coa);
                _this.banks[index].coaOut.options.push(e.coa);
                _this.banks[index].coaBalance.options.push(e.coa);
              })
            })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.costCenter.options =  resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataProcessDocument() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/process-document", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.processDocument.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getDataBank() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/bank", {
          params: {
            order: "name",
            sort: "asc",
          }, 
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.bank.options = resp.data.records;
            // var _this = this
            // this.banks.forEach(function(element, index){
            //   _this.banks[index].bank.options = resp.data.records;
            // })
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setCostCenterSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/cost-center/" + id).then((resp) => {
        if (resp.status == "success") {
          this.costCenter.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setWarehouseSelected(indextr, id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/warehouses/" + id).then((resp) => {
        if (resp.status == "success") {
          this.items[indextr].entityUnit.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setTerritorySelected(indextr, id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.items[indextr].entityUnit.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCoaInSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            this.banks[indextr].coaIn.selected = resp.data[0].coa;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setCoaOutSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            this.banks[indextr].coaOut.selected = resp.data[0].coa;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setCoaBalanceSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            this.banks[indextr].coaBalance.selected = resp.data[0].coa;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setProcessDocumentSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/process-document/" + id).then((resp) => {
            if (resp.status == "success") {
            this.banks[indextr].processDocument.selected = resp.data;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setBankSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/bank/" + id).then((resp) => {
            if (resp.status == "success") {
            this.banks[indextr].bank.selected = resp.data;            
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    entityTypeChange(val, index){
      if(val == "Warehouse"){
        this.getDataWarehouse(index);
      } else if (val == "Territory"){
        this.getDataTerritory(index);
      }
    },
    handleAddItem(){
      this.items.splice(this.items.length+1, 0, {
          num: 0,
          status: "minus",
          entityUnit:{
            selected: [],
            options: [],
          },
          entityType: {
            options: ["Warehouse", "Territory"],
            selected: ""
          },
      })
      
    },
    handleRemoveItem(indextr){
      this.items.splice(indextr, 1)
    },
    handleAddBank(){
      var _this = this
      this.banks.splice(this.banks.length+1, 0, {
          status: "minus",
          branch: "", 
          accountCode: "",
          accountName: "",   
          isDefault: false,     
          bank:{
              selected: [],
              options: _this.banks[0].bank.options,
          },       
          coaIn:{
              selected: [],
              options: _this.banks[0].coaIn.options,
          },
          coaOut:{
              selected: [],
              options: _this.banks[0].coaOut.options,
          },
          coaBalance:{
              selected: [],
              options: _this.banks[0].coaBalance.options,
          },
          processDocument:{
              selected: [],
              options: _this.banks[0].processDocument.options,
          },
          accountType: {
            options: ["Saving Account", "Checking Account", "Digital Money"],
            selected: "Saving Account"
          },
      })
      
    },
    handleRemoveBank(indextr){
      this.banks.splice(indextr, 1)
    },
    checkedDefault(indextr){
      if(this.banks[indextr].isDefault == true){
        var _this = this        
        this.banks.forEach(function(element, index){
            if (index != indextr){
              _this.banks[index].isDefault = false
            }
        })
      }
    },
  },
  mounted() {
    this.getDataCoa();
    this.getDataBank();
    this.getDataCostCenter();
    this.getDataProcessDocument();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      this.getDataCoa();
      this.getDataBank();
      this.getDataCostCenter();
      this.getDataProcessDocument();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>

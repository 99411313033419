<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <br><br>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Code" v-validate="'required'" v-model="data.code"/>
        <span class="text-danger text-sm" v-show="errors.has('Code')">{{ errors.first('Code') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input class="w-full" label="Name" v-validate="'required'" v-model="data.name"/>
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{ errors.first('Name') }}</span>
      </div>
    </div>
    <!-- <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Description</label>
        <textarea class="w-full" v-model="data.description"></textarea>
      </div>
    </div> -->
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Group</label>
        <multiselect
          v-model="data.group.selected"
          :options="data.group.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Group')">{{
          errors.first("Group")
        }}</span>
      </div>
    </div>
    
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Default Journal</label>
        <br />
        <ul class="centerx">
          <li>
            <vs-radio v-model="data.type" vs-value="Debit"
              >Debit</vs-radio
            >&nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <vs-radio v-model="data.type" vs-value="Kredit"
              >Kredit</vs-radio
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Allow non-default</label>
        <br />
        <ul class="centerx">
          <li>
            <vs-radio v-model="data.is_allow" :vs-value="true"
              >True</vs-radio
            >&nbsp;&nbsp;&nbsp;
          </li>
          <li>
            <vs-radio v-model="data.is_allow" :vs-value="false"
              >False</vs-radio
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          code: "", 
          //description: "", 
          type: "Debit", 
          is_allow: true, 
          group: {
            options: [],
            selected: null
          },
        },
        
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {

        if (result) {

          if (!this.data.group.selected) {
            this.errors.add({
              field: "Group",
              msg: "The Group field is required",
            });
            result = false;
          }
          
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        name: this.data.name,
        code: this.data.code,
        //description: this.data.description,
        type: this.data.type,
        is_allow: this.data.is_allow,
        chart_of_account_group_id: this.data.group.selected.ID,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.ID = resp.data.ID;
            this.data.code = resp.data.Code;
            this.data.name = resp.data.Name;
            //this.data.description = resp.data.Description;
            this.data.is_allow = resp.data.IsAllow;
            this.data.type = resp.data.Type;

            if (resp.data.ChartOfAccountGroupID) {
              this.setGroupSelected(resp.data.ChartOfAccountGroupID);
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataGroup() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account-group", {
          params: {
            order: "name",
            sort: "asc",
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.group.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setGroupSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/chart-of-account-group/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.group.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    this.getDataGroup();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      this.getDataGroup();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>

<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card>
            <vs-tabs :color="colorx">
                <!-- <vs-tab @click="colorx = 'success'" label="Posting Rule">
                    <div class="con-tab-ejemplo">
                        <posting-rule></posting-rule>
                    </div>
                </vs-tab> -->
                <vs-tab @click="colorx = 'success'" label="Operating Unit">
                    <div class="con-tab-ejemplo">
                    <operating-unit></operating-unit>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Chart Of Account">
                    <chart-of-account></chart-of-account>
                </vs-tab>
                <vs-tab @click="colorx = 'success'" label="Cost Center">
                    <cost-center></cost-center>
                </vs-tab>
                <!-- <vs-tab @click="colorx = 'success'" label="Analytic Mapping">
                    <analytic-mapping></analytic-mapping>
                </vs-tab> -->
                <!-- <vs-tab @click="colorx = 'success'" label="Analytic">
                    <analytic></analytic>
                </vs-tab> -->
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import postingRule from "./posting-rule/index.vue";
import operatingUnit from "./operating-unit/index.vue";
import Analytic from "./analytic/index.vue";
import analyticMapping from "./analytic-mapping/index.vue";
import chartOfAccount from "./chart-of-account/index.vue";
import costCenter from "./cost-center/index.vue";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        'posting-rule': postingRule,
        'operating-unit': operatingUnit,
        'analytic': Analytic,
        'analytic-mapping': analyticMapping,
        'chart-of-account': chartOfAccount,
        'cost-center': costCenter,
        Datepicker, 
    },
    data: () => ({
        colorx: "success",
    }),
};
</script>
 
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>